<template>

  <div>
    <div>
        <router-view  />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppMain",
};
</script>

<style>
.extra-width {
  margin-left: 140px !important;
}
</style>
