    
   export const getAccess =  (value) => {
        let data = document.cookie.split(";");
        for (let i in data) {
          let cur = data[i].split("=");
          if (cur[0].trim() === value && cur[1] != 'no access') {
            return cur[1];
          }
          
        }

        // return localStorage.getItem("vue_token");
        }

  export const clearCookies = () => {
          const cookies = document.cookie.split(";");
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie =
              name.trim() + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=hire3x.com;";
              // name.trim() + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
          }
        };