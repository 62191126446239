import Vue from 'vue'
import Vuex from 'vuex'
import { getPreference } from '../utils/apiFunction';
import {getAccess, clearCookies} from '../utils/helpers';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginpage: "",
  },
  getters: {
    
    getLoginPage: (state) => state.loginpage,
  },
  mutations: {
    setLoginPage(state, token) {
      state.loginpage = token;
  },
  },
  actions: {
    setPath(state){
      console.log(state.getters.getLoginPage)
      if(state.getters.getLoginPage == 'assessment'){
        if(getAccess('assessment') && getAccess('assessment') != 'no-access'){
              // console.log(getAccess('assessment'))
            //  window.open("http://localhost:8082/");
             window.open("https://judge.hire3x.com/");
        }
        else{
          clearCookies();
          // window.open("http://localhost:8080/?from=assessment");
          window.open("https://app.hire3x.com/?from=assessment");
        }
      }
      else if(state.getters.getLoginPage == 'recruitment' && (getAccess('recruitment') != 'no-access' || getAccess('candidate') != 'no-access')){
        if(getAccess('recruitment') && getAccess('recruitment') != 'no-access'){
          // console.log(getAccess('recruitment'))
          // window.open("http://localhost:8083/");
          window.open("https://recruitment.hirex3.com/");
        }
        else if( state.getters.getLoginPage == 'candidate'  && getAccess('candidate') && getAccess('candidate') != 'no-access'){
          // console.log(getAccess('candidate'))
          // window.open('http://localhost:8084/');
          window.open("https://candidate.hire3x.com/");
        }
        else{
          clearCookies();
          // window.open('http://localhost:8080/?from=recruitment');
          window.open("https://app.hire3x.com/?from=recruitment");
        }
        }
        else if(state.getters.getLoginPage == 'builder'){
          if((getAccess('candidate') && getAccess('candidate') != 'no-access')){
               // console.log(getAccess('assessment'))
          //  window.open("http://localhost:8085/");
           window.open("https://app.hire3x.com/login/?from=builder");
          }
          else{
            clearCookies();
            // window.open('http://localhost:8080/?from=builder');
          window.open("https://app.hire3x.com/login/?from=builder");
          }
        }
        // else if( state.getters.getLoginPage == 'candidate' && getAccess('candidate'))
        // window.open("http://localhost:8084/");
        else{
          clearCookies();
          // window.open(`http://localhost:8080/login/?from=${state.getters.getLoginPage}`)
          // window.open(`http://localhost:8080/login/?from=${state.getters.getLoginPage}`)
          window.open(`https://app.hire3x.com/login/?from=${state.getters.getLoginPage}`)
        }
    },
    async getPref(state){
      try{
       const res = await getPreference();
       if(res.status == 200){
        if(res.data.data.defaultModule != null){
            if(res.data.data.defaultModule == 'assessment'){
              //  window.open("http://localhost:8082/");
              window.open("https://judge.hire3x.com/");
              }
              else if(res.data.data.defaultModule == 'recruitment'){
              // window.open("http://localhost:8083/");
              window.open("https://recruitment.hire3x.com/");
              }
              else if(res.data.data.defaultModule == 'candidate')
              // window.open("http://localhost:8084/");
              window.open("https://candidate.hire3x.com/");
              else{
                // window.open(`http://localhost:8080/login/?from=${state.getters.getLoginPage}`)
                window.open(`https://app.hire3x.com/login/?from=${state.getters.getLoginPage}`)
              }
        }
        else{
          if(getAccess('recruitment') && getAccess('recruitment') != 'no-access'){
            // window.open("http://localhost:8083/");
            window.open("https://recruitment.hire3x.com/");
            }
             else if(getAccess('assessment') && getAccess('recruitment') != 'no-access'){
                // window.open("http://localhost:8082/");
                window.open("https://judge.hire3x.com/");
              }
              else if(getAccess('candidate')  && getAccess('candidate') != 'no-access'){
                // window.open('http://localhost:8084/');
                window.open("https://candidate.hire3x.com/");
              }
              // else if( state.getters.getLoginPage == 'candidate' && getAccess('candidate'))
              // window.open("http://localhost:8084/");
              else{
                window.open(`https://app.hire3x.com/login/?from=${state.getters.getLoginPage}`)
              }
        }    
   }
   else{
    window.open(`https://app.hire3x.com/login/?from=${state.getters.getLoginPage}`)
    // window.open(`http://localhost:8080/login/?from=${state.getters.getLoginPage}`)
   }
      }catch(error){
        window.open(`https://app.hire3x.com/login/?from=${state.getters.getLoginPage}`)
        // window.open(`http://localhost:8080/login/?from=${state.getters.getLoginPage}`)
       console.log(error)
      }
   },
   
  },
  modules: {
  }
})
