import router from "./router";


// page title function
const title = "Hire3x";

const getPageTitle = pageTitle => {
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
};

router.beforeEach((to, from, next) => {
  // Set page title
  document.title = getPageTitle(to.meta.title);


  // Proceed to the requested route.
  next();
});

router.afterEach(() => {});
