<template>
  <div>
    <div id="main-wrapper position-relative">
      <HeaderPage />
      <AppMainVue />
      <FooterPage />
    </div>
  </div>
</template>

<script>
import HeaderPage from "@/components/fixedComponents/HeaderPage.vue";
import FooterPage from "@/components/fixedComponents/FooterPage.vue";

import AppMainVue from "./AppMain.vue";

export default {
  name: "LayoutPage",

  components: {
    HeaderPage,
    FooterPage,
    AppMainVue,
  },

  data() {
    return {};
  },
  mounted() {
    document.addEventListener("DOMContentLoaded", function () {
      // Code to run when the document is ready
    });

    window.addEventListener("scroll", function () {
      let scroll = window.scrollY;

      let headerMain = document.querySelector(".header-main");
      if (scroll >= 50) {
        headerMain.classList.add("hitTop");
      } else {
        headerMain.classList.remove("hitTop");
      }
    });
  },
};
</script>
