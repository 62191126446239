import axios from "axios";
import jwt_decode from "jwt-decode";
const request = axios.create({
  // baseURL: "https://api-website.simplifycv.com/", // Development
  baseURL: "https://api-website.hire3x.com/", // Development
});
const SSOService = axios.create({
  baseURL:
    // "http://sso.simplifycv.com/", // Development
    // "https://auth.simplifymycareer.com/", // Staging & Development
  "https://auth.hire3x.com/" // Production
});

const websiteRequest = axios.create({
  // baseURL: "https://api-website.simplifymycareer.com",
  baseURL: "https://api-website.hire3x.com/",
});

websiteRequest.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + getToken();
    config.headers["userID"] = getProcessedToken();
    // config.headers["userID"] = "5b935330-0f65-4492-b158-5e3e7b9b8bd4";
    // config.headers["userID"] = "e562e893-3ed8-4073-9576-10373bc3e260";

    return config;
  },
  (error) => {
    // Do something with request error
    return error;
  }
);
function getProcessedToken() {
  var decoded = jwt_decode(getToken());
  return decoded.sub;
}
// Get token from localstorage
const getToken = () => {
let data = document.cookie.split(";");
for (let i in data) {
  let cur = data[i].split("=");
  if (cur[0].trim() === "accessToken") {
    return cur[1];
  }
}
// return localStorage.getItem("vue_token");
};

export { request, SSOService, websiteRequest };
