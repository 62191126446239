import Vue from "vue";
import VueRouter from "vue-router";
import LayoutPage from "../views/layout/LayoutPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",

    component: LayoutPage,
    redirect: "/",
    // meta: {
    //   title: "Home",
    // },

    children: [
      {
        path: "/",
        name: "HomePage",
        component: () => import("../views/HomePage.vue"),
        // meta: {
        //   title: "Home",
        // },
      },
      {
        path: "requestdemo",
        name: "RequestDemo",
        component: () => import("../views/RequestDemo.vue"),
        meta: {
          title: "Request Demo",
        },
      },
      {
        path: "contact",
        name: "ContactPage",
        component: () => import("../views/ContactPage.vue"),
        meta: {
          title: "Contact",
        },
      },
      {
        path: "builder",
        name: "BuilderPage",
        component: () => import("../views/BuilderPage.vue"),
        meta: {
          title: "Builder",
        },
      },

      {
        path: "recruitment",
        name: "RecruitmentPage",
        component: () => import("../views/RecruitmentPage.vue"),
        meta: {
          title: "Recruitment",
        },
      },
      {
        path: "assessment",
        name: "AssessmentPage",
        component: () => import("../views/AssessmentPage.vue"),
        meta: {
          title: "Assessment",
        },
      },
      {
        path: "training",
        name: "ProductPage",
        component: () => import("../views/ProductPage.vue"),
        meta: {
          title: "Training",
        },
      },
      {
        path: "payment",
        name: "PaymentPage",
        component: () => import("../views/PaymentPage.vue"),
        meta: {
          title: "Payment",
        },
      },
      {
        path: "privacy",
        name: "PrivacyPage",
        component: () => import("../views/PrivacyPage.vue"),
        meta: {
          title: "Privacy",
        },
      },
      {
        path: "terms",
        name: "TermsandConditions",
        component: () => import("../views/Terms&Conditions.vue"),
        meta: {
          title: "Terms & Conditions",
        },
      },
      {
        path: "referral",
        name: "Referral",
        component: () => import("../views/ReferralCode.vue"),
        meta: {
          title: "Terms & Conditions",
        },
      },
      {
        path: "careers",
        name: "CareerPage",
        component: () => import("../views/CareerPage.vue"),
        meta: {
          title: "Careers",
        },
      },
    ],
  },
  {
    path: "/support",

    redirect: "/support",

    component: LayoutPage,

    meta: {
      title: "Support",
    },
    children: [
      {
        path: "",
        name: "SupportPage",
        component: () => import("../views/SupportPage.vue"),
        meta: {
          title: "Support",
        },
      
      },
      {
        path: "assessmentsupport",
        name: "AssessmentSupport",
        component: () => import("../views/AssessmentSupport.vue"),
        meta: {
          title: "Assessment",
        },
      },
      {
        path: "topiccreation",
        name: "TopicCreation",
        component: () => import("../views/TopicCreation.vue"),
        meta: {
          title: "Topic Creation",
        },
      },
      {
        path: "accesspanel",
        name: "AccessPanel",
        component: () => import("../views/AccessPanel.vue"),
        meta: {
          title: "Access Panel",
        },
      },
      {
        path: "troubleshoot",
        name: "TroubleShoot",
        component: () => import("../views/TroubleShoot.vue"),
        meta: {
          title: "Trouble Shoot",
        },
      },

      {
        path: "testconfig",
        name: "TestConfig",
        component: () => import("../views/TestConfig.vue"),
        meta: {
          title: "Test Config",
        },
      },

      {
        path: "trust",
        name: "TrustPage",
        component: () => import("../views/TrustPage.vue"),
        meta: {
          title: "Trust Page",
        },
      },
      {
        path: "sharelink",
        name: "ShareLink",
        component: () => import("../views/ShareLink.vue"),
        meta: {
          title: "Share Link",
        },
      },
      {
        path: "profilebuilding",
        name: "ProfileBuilding",
        component: () => import("../views/ProfileBuilding.vue"),
        meta: {
          title: "Profile Building",
        },
      },
    ],
  },
];

const createRouter = () =>
  new VueRouter({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: routes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;

// To avoid Duplicate Navigation
const onError = (e) => {
  // avoid NavigationDuplicated
  if (e.name !== "NavigationDuplicated") throw e;
};

// Store the original push method
const originalPush = router.push;

// Override the push method
router.push = function push(...args) {
  // Your custom logic here

  try {
    // Call the original push method and handle promises
    const op = originalPush.call(this, ...args);
    if (op instanceof Promise) op.catch(onError);
    return op;
  } catch (e) {
    // Handle errors
    onError(e);
  }
};
