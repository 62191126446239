<template>
  <div id="app">
    {{ stateSync }}
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  computed: {
    stateSync() {
      let stt = this.$store.state;
      localStorage.setItem("simpAdminState", JSON.stringify(stt));
      return "";
    },
  },
  created() {
    let savedstate = localStorage.getItem("simpAdminState");
    if (savedstate != null) {
      this.$store.replaceState(JSON.parse(savedstate));
    }
  },
};
</script>

